<template>
  <div>
    <!-- Back button  if u want to use this breadcrumbs just remove the hidden class -->
    <div class="heading-sec">
      <div class="row">
        <div class="col-md-4 column">
          <div class="heading-profile">
            <div class="font-24 mt-10 ml-4">Create Celebrity Style</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Top Bar Chart -->
    <v-card class="mt-2 mx-4">
      <div class="panel-content">
        <div class="row">
          <div class="col-md-12">
            <div class="widget">
              <div class="form-elements-sec">
                <div class="form-body">
                  <form
                    title="details"
                    class="formPart text-left"
                    @submit.prevent="submitForm(details)"
                  >
                    <!-- title -->

                    <div>
                      <div class="form-group col">
                        <label for="text">Title:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="text"
                          placeholder="Enter Title"
                          v-model.trim="details.title"
                          @input="setTouch('title')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.title.required && $v.details.title.$error"
                        >
                          Title is required
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="form-group col">
                        <label for="name">Designer:</label>
                        <v-select
                          prepend-icon=""
                          item-text="brandName"
                          v-model="details.user._id"
                          :items="brands"
                          class="form-control"
                          append-icon="▼"
                        >
                          <template v-slot:selection="{ item }">
                            <v-row no-gutters>
                              <v-col cols="12">
                                <div>{{ item.brandName }}</div>
                              </v-col>
                            </v-row>
                          </template>
                          <template v-slot:item="{ item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-content>
                                <div>{{ item.brandName }}</div>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-select>
                        <div
                          class="validation"
                          v-if="!$v.details.user.required && $v.details.user.$error"
                        >
                          Designer Name is required
                        </div>
                      </div>
                    </div>
                    <!-- Email -->

                    <!-- phone no -->
                    <!-- <div>
                      <div class="form-group col">
                        <label for="image">image:</label>
                        <v-file-input
                          prepend-icon=""
                          type="file"
                          class="form-control"
                          id="spotlight-image"
                          placeholder="Select Image"
                          v-model="details.image"
                          @change="handleImage"
                          accept="image/jpeg, image/png"
                          v-model.trim="details.image"
                        ></v-file-input>
                        <div
                          class="validation"
                          v-if="!$v.details.image.required && $v.details.image.$error"
                        >
                          Image is required
                        </div>
                      </div>
                      <div class="preview-image border p-2 mt-3 ml-3" v-if="url">
                        <v-img :src="url" height="230" contain></v-img>
                      </div>
                    </div> -->
                    <div class="d-flex">
                      <div class="form-group col">
                        <div>
                          <label for="image">Mobile Image:</label>
                          <div class="font-12 black--text font-weight-bold">
                            (163 W &#10005; 222 H)
                          </div>
                          <v-file-input
                            prepend-icon=""
                            type="file"
                            class="form-control"
                            id="banner-image"
                            v-model.trim="details.imageMobile"
                            placeholder=" Select Image"
                            accept="image/jpeg, image/png"
                            @change="handleMobileImage"
                          ></v-file-input>
                          <div
                            class="validation"
                            v-if="!$v.details.imageMobile.required && $v.details.imageMobile.$error"
                          >
                            Mobile Image is required
                          </div>
                        </div>
                        <div class="preview-image border p-2 mt-3 ml-3" v-if="mobileUrl">
                          <v-img :src="mobileUrl" height="230" width="100%" contain></v-img>
                        </div>
                      </div>

                      <div class="form-group col">
                        <div>
                          <label for="image"> Desktop Image:</label>
                          <div class="font-12 black--text font-weight-bold">
                            (227 W &#10005; 322 H)
                          </div>
                          <v-file-input
                            prepend-icon=""
                            type="file"
                            class="form-control"
                            id="banner-image"
                            v-model.trim="details.image"
                            placeholder=" Select Image"
                            @change="handleDesktopImage"
                            accept="image/jpeg, image/png"
                          ></v-file-input>
                          <div
                            class="validation"
                            v-if="!$v.details.image.required && $v.details.image.$error"
                          >
                            Desktop Image is required
                          </div>
                        </div>
                        <div class="preview-image border p-2 mt-3 mr-3" v-if="desktopUrl">
                          <v-img :src="desktopUrl" height="230" contain></v-img>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="form-group col">
                        <label for="link">Link:</label>
                        <input
                          type="text"
                          class="form-control"
                          id="link"
                          placeholder="Enter Url"
                          v-model.trim="details.link"
                        />
                        <!-- <div
                          class="validation"
                          v-if="!$v.details.link.required && $v.details.link.$error"
                        >
                          Link is required
                        </div> -->
                      </div>
                    </div>
                    <div>
                      <div class="form-group col">
                        <label for="link"
                          >Palettier SKU Code:
                          <!-- <v-btn @click="addSku" dense>Add</v-btn> -->
                        </label>
                        <!-- <input
                          type="text"
                          class="form-control"
                          id="link"
                          placeholder="Enter Url"
                          v-model.trim="details.link"
                          @input="setTouch('link')"
                        />
                        <div
                          class="validation"
                          v-if="!$v.details.link.required && $v.details.link.$error"
                        >
                          Link is required
                        </div> -->
                        <div class="poppins-medium font-16 d-block">
                          <!-- <v-row no-gutters>
                            <v-col
                              cols="6"
                              v-for="(item, index) in details.query"
                              :key="index"
                              class=""
                            > -->
                          <v-text-field
                            width="200px"
                            outlined
                            dense
                            class="mt-6 mr-4 px-2"
                            v-model="details.sku"
                            >{{ details.sku }}</v-text-field
                          >
                          <!-- </v-col> -->

                          <!-- <v-col cols="4">
                              <img
                                @click="confirm(image, 'image')"
                                src="@/assets/Images/remove.svg"
                                alt="logo"
                                class="pl-15"
                              />
                            </v-col> -->
                          <!-- </v-row> -->
                        </div>
                      </div>
                    </div>
                    <!-- button -->
                    <div class="btnPart text-center my-4">
                      <button type="submit" class="btn btn-success">Save</button>
                      <p class="typo__p" v-if="submitStatus === 'OK'">
                        Thanks for your submission!
                      </p>
                      <p class="typo__p" v-if="submitStatus === 'ERROR'">
                        Please fill the form correctly.
                      </p>

                      <button type="reset" class="btn btn-danger mx-3" v-on:click="resetForm()">
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import { required } from 'vuelidate/lib/validators';
import homeService from '../../services/homeService';
import imageUploadService from '../../services/imageUploadService';
import productService from '../../services/productService';

export default {
  data() {
    return {
      details: {
        title: '',
        imageMobile: '',
        image: '',
        link: '',
        user: {
          _id: '',
        },
        name: '',
        sku: '',
        userId: '',
      },
      submitStatus: null,
      mobileUrl: null,
      desktopUrl: null,
      brands: [],
    };
  },

  validations: {
    details: {
      title: {
        required,
      },
      imageMobile: {
        required,
      },
      image: {
        required,
      },
      // link: {
      //   required,
      // },
      user: {
        required,
      },
    },
  },

  methods: {
    // this method use for validation error
    setTouch(fields) {
      if (fields === 'title') {
        this.$v.details.title.$touch();
      } else if (fields === 'mobileImage') {
        this.$v.details.imageMobile.$touch();
      } else if (fields === 'image') {
        this.$v.details.image.$touch();
      }
      //  else if (fields === 'link') {
      //   this.$v.details.link.$touch();
      // }
      else if (fields === 'user') {
        this.$v.details.user.$touch();
      }
    },

    // this is use for save button
    async submitForm(details) {
      console.log(details, 'details');
      this.$v.$touch();
      details.user = await this.findBrandId(details.user._id);

      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR';
        this.$store.commit('setSnackbar', {
          content: 'please fill form correctly',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      } else {
        //     // do your submit logic here
        this.submitStatus = 'OK';

        const result = await homeService.createSpotLight(details);
        if (result.status === 200) {
          this.$store.commit('setSnackbar', {
            content: 'Submitted Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
          this.$router.push('/viewSpotLight');
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      }
    },
    // this method use for cancel button
    resetForm() {
      this.details = 'blank';
      this.$nextTick(() => {
        this.$v.$reset();
        this.$router.push('/viewSpotLight');
      });
    },
    // async addSku() {
    //   const data = {};
    //   data.sku = '';
    //   this.details.query.push(data);
    // },

    async findBrandId(name) {
      const finalLayer = await _.find(this.brands, (n) => {
        const obj = n.brandName === name;
        return obj;
      });
      return finalLayer._id;
    },
    async createSpotLight(details) {
      console.log('result');
      const result = await homeService.createSpotLight(details);
      console.log('result', result);
    },
    async handleDesktopImage(file) {
      this.desktopUrl = URL.createObjectURL(this.details.image);
      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          this.details.image = result.data.meta.location;
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async handleMobileImage(file) {
      this.mobileUrl =  URL.createObjectURL(this.details.imageMobile);

      imageUploadService.uploadImage(file, (result) => {
        if (result.status === 200) {
          console.log(result.data.meta.location, 'meta');
          this.details.imageMobile = result.data.meta.location;

          console.log(this.details.image, 'meta2');
          this.$store.commit('setSnackbar', {
            content: 'Image Uploaded Successfully',
            icon: 'mdi-check-circle',
            color: 'success ',
            isVisible: true,
          });
        } else {
          this.$store.commit('setSnackbar', {
            content: 'Something went wrong !',
            icon: 'mdi-alert-circle',
            color: 'error ',
            isVisible: true,
          });
        }
      });
    },
    async getAllBrands() {
      const result = await productService.getAllBrands();
      this.brands = await result.data;
      console.log(this.brands, 'brands');
    },
  },
  async created() {
    await this.getAllBrands();
  },
};
</script>
<style lang="scss" scoped>
.validation {
  color: red;
}
.preview-image {
  width: 300px;
  height: 250px;
}
</style>
